.blogReferenceComponent {
  display: grid;
  background: var(--light-background);
  padding: 75px 0px;
  gap: 50px;

  header {
    display: grid;
    grid-template-columns: 7fr 5fr;
    align-items: center;
    margin-bottom: -10px;

    .button {
      align-items: center;
      justify-self: flex-end;
      width: fit-content;
    }

    .contentWrapper {
      gap: 20px;
      display: grid;

      .heading {
        margin-bottom: 10px;

        &::before {
          background: var(--light-background);
        }
      }

      .title {
        max-width: 588px;
      }
    }
  }

  .blogEntries {
    display: grid;

    .slider {
      position: relative;
      min-width: 0px;
      width: 100%;

      .slide {
        display: grid;
        filter: blur(2px);

        &[data-selected='true'] {
          filter: blur(0px);
        }

        &:hover {
          filter: blur(0px);
        }

        .blogEntry {
          display: grid;
          gap: 25px;
          padding-bottom: 10px;
          padding-top: 10px;

          .blogLink {
            position: relative;
            overflow: hidden;
            border-radius: 16px;

            .card {
              display: grid;
              gap: 10px;
              grid-template-columns: auto 1fr;
              position: relative;
              background-color: var(--midnight-green-500);
              width: 75%;
              left: 20px;
              justify-self: center;
              border-radius: 16px;
              padding: 5px 0px 7px 18px;
              align-items: flex-start;
              margin-bottom: -20px;
              color: var(--neutral-100);
              font-size: clamp(calc(20rem/16), calc(20vw/7.68), calc(24rem/16));
            }

            .image {
              height: 100%;
              width: 100%;
              object-fit: cover;
              display: block;
              border-radius: 16px;
              aspect-ratio: 1/1;
            }
          }

          .headingsWrapper {
            display: flex;
            gap: clamp(10px, calc(10vw/7.68), 15px);
            flex-wrap: wrap;

            .heading {
              &::before {
                background: inherit;
              }
            }
          }

          .overlay {
            position: relative;

            .description {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;

              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(249, 249, 249, 0.836) 100%);
                pointer-events: none;
              }
            }
          }

          .link {
            font-size: clamp(16px, calc(24vw/7.68), 24px);
            color: var(--coffee-500);
            text-decoration: underline;
            width: fit-content;
          }
        }
      }
    }

    .controls {
      padding-top: 40px;
      display: flex;
      justify-content: center;
      gap: 15px;

      .dot {
        align-self: center;
        height: clamp(12px, calc(16vw/7.68), 17px);
        width: clamp(12px, calc(16vw/7.68), 17px);
        border-radius: 50%;
        border: 1px solid var(--dark-neutral-900);
        margin: 0px 5px;
        transition: all 0.3s ease-in-out;

        &[data-selected='true'] {
          transform: scale(1.1);
          background: var(--dark-neutral-900);
        }
      }

      >button {
        display: flex;
        align-items: center;
      }
    }
  }

  @media(max-width: 999px) {
    .blogEntries {
      .slider {
        margin: 0px calc(var(--pageMargin) * -1);
        width: calc(100% + var(--pageMargin) * 2);
      }
    }

    header {
      grid-template-columns: 1fr;

      .button {
        justify-self: flex-start;
        margin-top: 50px;
      }
    }
  }

  @media(max-width: 499px) {
    header {
      .button {
        width: 100%;
      }
    }
  }
}