.tilesComponent {
  display: grid;
  gap: 50px;

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;
    row-gap: clamp(40px, calc(40vw/7.68), 50px);
    column-gap: 24px;

    .item {
      display: grid;
      gap: 16px;
      text-align: center;

      .image {
        height: auto;
        width: 100%;
        display: block;
        object-fit: contain;
      }

      .description {
        padding-top: 9px;
        color: var(--midnight-green-900);

        >p {
          font-size: 20px;
        }
      }
    }
  }

  .item:nth-child(1) {
    margin-right: 35px;
  }

  .item:nth-child(3) {
    margin-left: 35px;
  }

  .item:nth-child(2) {
    border: 1px solid var(--border);
    border-radius: 16px;
    padding-bottom: 29px;
    margin: 0px -35px;
  }

  @media (max-width: 1199px) {
    .list {
      grid-template-columns: 1fr 1fr;

      .item {
        margin: unset;
        border: unset;

        .image {
          border-radius: 16px;
        }
      }
    }
  }

  @media (max-width: 499px) {
    .list {
      grid-template-columns: 1fr;
    }
  }
}