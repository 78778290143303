.newsletterSection {
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  align-items: flex-start;
  grid-template-areas: "content visuals";

  .content {
    display: grid;
    row-gap: 30px;
    grid-area: content;

    header {
      display: grid;
      grid-gap: 14px;
    }

    .description {
      display: grid;
      grid-gap: 16px;
    }

    .form {
      display: grid;
      gap: 20px;
      position: relative;

      .loader {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(212, 212, 212, 0.171);
        backdrop-filter: blur(2px);
        z-index: 2;
        display: grid;
        align-items: center;
        justify-items: center;
        border-radius: 16px;

        >span {
          position: absolute;
          color: var(--midnight-green-200);
          font-size: 32px;
        }

        .circle {
          animation: rotate 2s linear infinite;
        }


        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }

          to {
            transform: rotate(360deg);
          }
        }
      }

      >button {
        margin-top: 25px;
      }
    }
  }

  .visuals {
    position: relative;
    grid-area: visuals;

    .card {
      position: relative;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-areas: "img heading" "img description";
      align-items: center;
      background: var(--background);
      column-gap: 14px;
      max-width: 500px;
      border-radius: 16px;
      box-shadow: 3px 2px 8px 0px rgba(143, 143, 143, 0.10),
        13px 7px 15px 0px rgba(143, 143, 143, 0.09);
      padding: 14px 40px 16px 12px;
      margin-bottom: -40px;

      .image {
        width: clamp(62px, calc(62vw/7.68), 78px);
        height: clamp(62px, calc(62vw/7.68), 78px);
        border-radius: 50%;
        grid-area: img;
        margin: unset;
      }

      .heading {
        grid-area: heading;
      }

      .description {
        grid-area: description;

        >p {
          color: var(--neutral-900);
        }
      }
    }

    .image {
      height: auto;
      width: 100%;
      object-fit: cover;
      margin-left: 20px;
      border-radius: 20px;
      display: block;
      max-height: 600px;
      object-position: 0% 20%;
    }
  }

  .state {
    display: grid;
    grid-row-gap: 24px;

    >h3 {
      .success {
        color: var(--green-800);
      }

      .failed {
        color: var(--error-800);
      }
    }

    >p {
      font-size: clamp(calc(16rem/16), calc(20vw/7.68), calc(24rem/16));
    }

    >button {
      margin-top: 16px;
    }
  }

  @media (max-width: 999px) {
    grid-template-columns: 1fr;
    grid-template-areas: "visuals" "content";
    row-gap: 48px;

    .visuals {
      .image {
        aspect-ratio: 20/9;
        margin-left: unset;
        border-radius: 20px;
      }

      .card {
        margin-left: 36px;
      }
    }

    .content {
      header {
        max-width: 250px;
      }
    }
  }

  @media (max-width: 499px) {
    row-gap: 31px;

    .visuals {
      .image {
        aspect-ratio: unset;
      }

      .card {
        margin-left: 0px;
      }
    }

    .content {
      header {
        max-width: unset;
      }
    }
  }
}